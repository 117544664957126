import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Avatar from '@base/Avatar/Avatar';
import Menu from '@base/Menu/Menu';
import Link from '@base/Link/Link';
import Dynamic from '@base/Dynamic/Dynamic';
import { Spacings } from '@styles/index';
import usePurchasedItems from '@api/hooks/auth/usePurchasedItems';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useAccessControl from '@api/hooks/auth/useAccessControl';
import {
  getThumbnail,
  getFullName,
  getMeUsername,
  getMeUid,
  getIsEducator,
  getMeRelativeLink,
  getIsModerator,
  getIsPartner,
  getIsDoubtTopologyPresent,
  getIsReviewer,
  getIsEvaluator,
  getExternalContentUploader,
  getIsLiveInteractionEnabled,
  getMe
} from '@api/selectors/auth';
import {
  getIsSubscribedToPlusWithoutTrial,
  getIsSubscribedToPlatformBatchGroup,
  getActiveUidsByType
} from '@api/selectors/subscription';
import { logout } from '@api/fetchers/auth';
import signOutUser from '@utils/signOutUser';
import useEducatorContext from '@hooks/useEducatorContext';
import { dashboardTouchpointEvent } from '@events/sendAnalytics';
import { segment } from '@actions/analytics';
import { REFERRAL_TOUCHPOINT_CLICKED } from '@constants/segment';
import useGoalEventProps from '@hooks/useGoalEventProps';

import HelpIcon from '@icon/ProfileDropdown/Help';
import ReferIcon from '@icon/ProfileDropdown/Refer';
import SettingsIcon from '@icon/ProfileDropdown/Settings';
import ExitIcon from '@icon/ProfileDropdown/Exit';
import EducatorDashboardIcon from '@icon/ProfileDropdown/EducatorDashboard';
import ModeratorDashboardIcon from '@icon/ProfileDropdown/ModeratorDashboard';
import ProfileIcon from '@icon/ProfileDropdown/Profile';
import MyDashboardIcon from '@icon/ProfileDropdown/MyDashboard';

import useIsCampusAmbassador from '@api/hooks/auth/useIsCampusAmbassador';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import ProfileItem from './ProfileItem';
import DarkModeOption from './DarkModeOption';
import Footer from './Footer';
import profileOptionsMap from './profileOptionsMap';

const ReportAnIssueOption = Dynamic(() => import('./ReportAnIssueOption'));

const UserAvatar = styled(Avatar)`
  display: block;
  position: relative;
  box-sizing: content-box;
  transition: border-color 300ms ease-in-out;
  border: 1px solid
    ${({ $isActive }) =>
      $isActive ? 'var(--color-base-fill)' : 'var(--color-divider)'};
  &:hover {
    border-color: var(--color-base-fill);
  }
`;

const StyledMenu = styled(Menu)`
  &.MuiPopover-root .MuiPaper-root {
    margin-top: 12px;
    .MuiList-root {
      padding: ${Spacings.SPACING_6} 0;
      position: relative;
    }
  }
`;

const anchorStyle =
  'display: flex; justify-content: space-between; width: 100%;';

const getEducatorDashboardLink = ({
  isDoubtTopologyPresent,
  isReviewer,
  isEvaluator,
  isExternalContentUploader,
  isLiveInteractionEnabled,
  isCampusAmbassador
}) => {
  if (isDoubtTopologyPresent)
    return { href: '/educator/doubts', as: '/educator/doubts' };
  if (isReviewer)
    return { href: '/educator/test/review', as: '/educator/test/review' };
  if (isEvaluator)
    return { href: '/educator/test/evaluate', as: '/educator/test/evaluate' };
  if (isExternalContentUploader)
    return { href: '/educator/test/upload', as: '/educator/test/upload' };
  if (isLiveInteractionEnabled)
    return {
      href: '/educator/one-to-one-live-mentorship',
      as: '/educator/one-to-one-live-mentorship'
    };
  if (isCampusAmbassador)
    return {
      href: '/educator/campus-ambassador',
      as: '/educator/campus-ambassador'
    };
  return { href: '/educator/kyc-status', as: '/educator/kyc-status' };
};

const ProfileOptions = ({ excludeOptions, closeStreakPopup }) => {
  const isEducatorSide = useEducatorContext();

  const me = useMyInfo(getMe);
  const meUID = useMyInfo(getMeUid);
  const meThumbnail = useMyInfo(getThumbnail);
  const fullName = useMyInfo(getFullName);
  const username = useMyInfo(getMeUsername);
  const isEducator = useMyInfo(getIsEducator);
  const isModerator = useMyInfo(getIsModerator);
  const relativeLink = useMyInfo(getMeRelativeLink);
  const isSubscribedToPlusWithoutTrial = useMyInfo(
    getIsSubscribedToPlusWithoutTrial
  );
  const latestUserSubscriptions = useMyInfo(getActiveUidsByType);
  const isSubscribedToPlatformBatch = usePurchasedItems(
    getIsSubscribedToPlatformBatchGroup
  );

  const isSubscribedToPlusOrPlatfrom =
    isSubscribedToPlusWithoutTrial || isSubscribedToPlatformBatch;

  const selectedGoalUID = useSelectedGoalUID();
  const { accessControl } = useAccessControl({ goalUID: selectedGoalUID });
  const isDoubtTopologyPresent = getIsDoubtTopologyPresent(accessControl);
  const isReviewer = getIsReviewer(accessControl);
  const isEvaluator = getIsEvaluator(accessControl);
  const isExternalContentUploader = getExternalContentUploader(accessControl);
  const isLiveInteractionEnabled = getIsLiveInteractionEnabled(accessControl);
  const isPartner = getIsPartner(me, accessControl);

  const { isCampusAmbassador } = useIsCampusAmbassador();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAvatarOnClick = (e) => {
    setAnchorEl(e.currentTarget);
    closeStreakPopup();
  };

  const {
    query: { goalUID },
    pathname
  } = useRouter();
  const routeGoalSegmentData = useGoalEventProps({ goalUID });

  const sendSegmentEvent = useCallback(
    (eventName, additionalProps = {}) => {
      segment.track(eventName, {
        ...routeGoalSegmentData,
        last_primary_source_section: 'Profile Dropdown',
        ...additionalProps,
        referral_type: latestUserSubscriptions?.length ? 'Paid' : 'Free'
      });
    },
    [latestUserSubscriptions?.length, routeGoalSegmentData]
  );

  const profileOptions = useMemo(() => {
    const profile = {
      label: 'Profile',
      value: profileOptionsMap.PROFILE,
      url: {
        href:
          isEducator || isModerator ? '/[authorID]' : '/learner/[learnerUID]',
        as: relativeLink
      },
      leftIconComponent: ProfileIcon,
      onClick: () => {
        if (isEducator || isModerator) return;
        sendSegmentEvent('Learner Profile - Clicked', {
          profile_uid: meUID,
          profile_username: username
        });
      }
    };

    const settings = {
      label: 'Settings',
      value: profileOptionsMap.SETTINGS,
      url: isEducator
        ? { href: '/educator/settings', as: '/educator/settings' }
        : { href: '/settings', as: '/settings' },
      leftIconComponent: SettingsIcon,
      onClick: () => {
        sendSegmentEvent('Settings - Settings Section Viewed');
      }
    };

    const helpAndSupport = {
      label: 'Help & support',
      value: profileOptionsMap.HELP_AND_SUPPORT,
      url: { href: '/helpcenter', as: '/helpcenter' },
      leftIconComponent: HelpIcon,
      onClick: () => {
        sendSegmentEvent('Help & Support - Viewed');
      }
    };

    const moderatorDashboard = {
      label: 'Moderator dashboard',
      value: profileOptionsMap.MODERATOR_DASHBOARD,
      url: {
        href: '/moderator/dashboard/[type]',
        as: '/moderator/dashboard/educators'
      },
      newTab: true,
      leftIconComponent: ModeratorDashboardIcon,
      onClick: (selectedOption) => {
        dashboardTouchpointEvent(selectedOption.label, meUID, username);
      }
    };

    const educatorDashboard = {
      label: 'Educator dashboard',
      value: profileOptionsMap.EDUCATOR_DASHBOARD,
      url: { href: '/educator', as: '/educator' },
      newTab: true,
      leftIconComponent: EducatorDashboardIcon
    };

    const myDashboard = {
      label: 'My dashboard',
      value: profileOptionsMap.MY_DASHBOARD,
      url: getEducatorDashboardLink({
        isDoubtTopologyPresent,
        isReviewer,
        isEvaluator,
        isExternalContentUploader,
        isLiveInteractionEnabled,
        isCampusAmbassador
      }),
      newTab: true,
      onClick: (selectedOption) => {
        dashboardTouchpointEvent(selectedOption.label, meUID, username);
      },
      leftIconComponent: MyDashboardIcon
    };

    const referYourFriends = {
      label: 'Refer your friends',
      value: profileOptionsMap.REFER_YOUR_FRIENDS,
      url: {
        href: { pathname: '/settings/referral' },
        as: '/settings/referral'
      },
      leftIconComponent: ReferIcon,
      onClick: () => {
        sendSegmentEvent(REFERRAL_TOUCHPOINT_CLICKED);
      }
    };

    const signOut = {
      label: 'Sign out',
      value: profileOptionsMap.SIGN_OUT,
      leftIconComponent: ExitIcon,
      onClick: () => {
        sendSegmentEvent('Settings - Signed Out');
        window.analytics?.reset();
        window.Intercom?.('shutdown');
        logout();
        signOutUser();
      }
    };

    const showDarkMode = !(
      excludeOptions.includes('darkMode') ||
      [
        '/quiz/[quizSlug]/[quizUID]',
        '/quiz/[quizSlug]/[quizUID]/solutions/[sessionUID]'
      ].includes(pathname) ||
      isEducatorSide
    );

    const options = [profile];

    if (showDarkMode) {
      options.push({
        element: (
          <DarkModeOption
            key={profileOptionsMap.DARK_MODE}
            sendSegmentEvent={sendSegmentEvent}
          />
        )
      });
    }

    options.push(settings);

    if (!isEducatorSide && !isPartner) {
      options.push(
        isSubscribedToPlusOrPlatfrom
          ? helpAndSupport
          : {
              element: (
                <ReportAnIssueOption
                  key={profileOptionsMap.REPORT_AN_ISSUE}
                  closeMenu={handleMenuClose}
                  sendSegmentEvent={sendSegmentEvent}
                />
              )
            }
      );
    }

    if (isModerator) options.push(moderatorDashboard);

    if (isEducator) options.push(educatorDashboard);

    if (isPartner || isCampusAmbassador) options.push(myDashboard);

    options.push(referYourFriends);
    options.push(signOut);

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    excludeOptions,
    handleMenuClose,
    isDoubtTopologyPresent,
    isEducator,
    isEducatorSide,
    isEvaluator,
    isExternalContentUploader,
    isLiveInteractionEnabled,
    isModerator,
    isReviewer,
    isSubscribedToPlusOrPlatfrom,
    meUID,
    relativeLink,
    pathname,
    sendSegmentEvent,
    isPartner,
    username,
    isCampusAmbassador
  ]);

  const handleOptionClick = (selectedOption) => {
    handleMenuClose();
    const handler = selectedOption.onClick;
    if (handler) handler(selectedOption);
  };

  return (
    <>
      <UserAvatar
        size="32px"
        src={meThumbnail}
        onClick={handleAvatarOnClick}
        alt={fullName}
        circular
        $isActive={!!anchorEl}
      />
      <StyledMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        position="right"
        height="auto"
        width="235px"
      >
        {profileOptions.map((selectedOption) => {
          const {
            url = {},
            label,
            value,
            rightIcon,
            isButton,
            newTab,
            onClick,
            leftIconComponent,
            element
          } = selectedOption;
          if (element) return element;
          const LeftIcon = leftIconComponent;
          const item = (
            <ProfileItem
              label={label}
              value={value}
              leftIcon={
                <LeftIcon color="var(--color-text-secondary)" size="20px" />
              }
              onClick={isButton ? onClick : undefined}
              rightIcon={rightIcon}
            />
          );
          return isButton ? (
            item
          ) : (
            <Link
              anchorStyle={anchorStyle}
              disabled={!url.href}
              href={url.href}
              as={url.as}
              onClick={() => handleOptionClick(selectedOption)}
              newTab={newTab}
              key={value}
            >
              {item}
            </Link>
          );
        })}
        <Footer />
      </StyledMenu>
    </>
  );
};

ProfileOptions.propTypes = {
  excludeOptions: PropTypes.arrayOf(PropTypes.string),
  closeStreakPopup: PropTypes.func
};

ProfileOptions.defaultProps = {
  excludeOptions: [],
  closeStreakPopup: () => null
};

export default ProfileOptions;
